import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchWeeks(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("fetchWeeks", { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchTasks(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("fetchTasks", { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    getScheduleByWeek(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("getScheduleByWeek", { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    getScheduleByWeeks(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("getScheduleByWeeks", { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    duplicateWeeks(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("duplicateWeeks", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    multipleDuplicateWeeks(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("multipleDuplicateWeeks", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteTask(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("deleteScheduleTask", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateSchedule(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("updateSchedule", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    addNewSchedule(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("addNewSchedule", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    addNewScheduleDashboard(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("addNewScheduleDashboard", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    dragSchedule(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("dragSchedule", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    
    updateScheduleTime(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("updateScheduleTime", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    downloadPDF(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("downloadPDFSchedule", data, { responseType: "blob" })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    downloadUserPDF(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("downloadUserPDFSchedule", data, { responseType: "blob" })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    createRoutine(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("createRoutine", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    createBuilder(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("createBuilder", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    

    hideSchedule(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("hideSchedule", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    unhideSchedule(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("unhideSchedule", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateRotaLocationUser(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("updateRotaLocationUser", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
