<template>
  <div>
    <button v-if="day.change_awaits" :style="buttonStyle(day)" @mouseenter="showOldTask(day)" @mouseleave="hideOldTask(day)" :id="targetId" class="popoverBtn" @contextmenu.prevent="$refs[targetId].open">
      <span :class="{ strikethrough: day.change_awaits && !day.isHovered }">{{ displayedTaskTitle }}</span>
      <div class="time-info-container" v-if="day.task_id != 1 && day.task_id != 2">
        <div class="time-info left">
          <span class="time">{{ day.start_time }}</span>
        </div>
        <div class="time-info right">
          <span class="time">{{ day.end_time }}</span>
        </div>
      </div>
    </button>

    <button v-else :style="buttonStyle(day)" :id="targetId" class="popoverBtn" @contextmenu.prevent="$refs[targetId].open">
      <span>{{ displayedTaskTitle }}</span>

      <div class="time-info-container" v-if="day.task_id != 1 && day.task_id != 2">
        <div class="time-info left">
          <span class="time">{{ day.start_time }}</span>
        </div>
        <div class="time-info right">
          <span class="time">{{ day.end_time }}</span>
        </div>
      </div>
    </button>

    <b-popover v-show="showPopover" :key="'left-click-' + targetId" :target="targetId" triggers="click blur" variant="secondary" placement="bottom">
      <div class="d-flex flex-column">
        <button v-for="(newTask, taskIndex) in tasks" @click="changeTask(newTask, true)" :key="taskIndex" class="mb-25" style="padding:5px 25px; border:solid 1px black;">{{ newTask.title }}</button>
      </div>
    </b-popover>

    <b-modal :ref="'time-modal-' + targetId" :key="'add-modal-' + targetId" hide-footer centered :title="'Task ID :' + targetId" size="sm">
      <div>
        <div class="mb-1">
          <b-form-radio-group v-model="selectedPeriod">
            <b-form-radio value="AM">AM</b-form-radio>
            <b-form-radio value="PM">PM</b-form-radio>
          </b-form-radio-group>
        </div>

        <div class="d-flex justify-content-between mt-1" style="width: 100%;">
          <div style="width: 48%;">
            <p>Start Time</p>
            <flat-pickr v-model="start_time" class="form-control" :config="startPickerConfig" />
          </div>

          <div style="width: 48%;">
            <p>End Time</p>
            <flat-pickr v-model="end_time" class="form-control" :config="endPickerConfig" />
          </div>
        </div>
      </div>

      <div class="d-flex mt-3 justify-content-end">
        <b-button variant="primary" @click="saveTimeChanges(targetId)">Save</b-button>
      </div>
    </b-modal>

    <b-modal :ref="'add-modal-' + targetId" :key="'time-modal-' + targetId" hide-footer centered :title="'Task ID :' + targetId" size="sm">
      <div>
        <div style="width: 100%;">
          <p>Task</p>
          <treeselect v-model="newTaskId" :multiple="false" :options="mappedTasks" :clearable="false" style="width: 100%;">
            <template #option="{ node }">{{ node.title }}</template>
          </treeselect>
        </div>

        <div class="mb-1 mt-1">
          <b-form-radio-group v-model="selectedPeriod">
            <b-form-radio value="AM">AM</b-form-radio>
            <b-form-radio value="PM">PM</b-form-radio>
          </b-form-radio-group>
        </div>

        <div class="d-flex justify-content-between mt-1" style="width: 100%;">
          <div style="width: 48%;">
            <p>Start Time</p>
            <flat-pickr v-model="start_time" class="form-control" :config="startPickerConfig" />
          </div>

          <div style="width: 48%;">
            <p>End Time</p>
            <flat-pickr v-model="end_time" class="form-control" :config="endPickerConfig" />
          </div>
        </div>
      </div>

      <div class="d-flex mt-3 justify-content-end">
        <b-button variant="primary" @click="saveAddChanges(targetId)">Save</b-button>
      </div>
    </b-modal>

    <vue-context :ref="targetId" :key="'right-click-' + targetId">
      <li>
        <b-link v-for="data in menuData" :key="data.text" class="d-flex align-items-center" @click="optionClicked(data.text, data.icon, data.where, targetId)">
          <feather-icon :icon="data.icon" size="16" />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
      </li>
    </vue-context>
  </div>
</template>

<script>
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import VueContext from 'vue-context';
import flatPickr from 'vue-flatpickr-component';
import Treeselect from '@riophae/vue-treeselect';

export default {
  components: {
    ToastificationContent,
    VueContext,
    flatPickr,
    Treeselect,
  },

  props: {
    day: Object,
    oldDay: Object,
    tasks: Array,
    targetId: String,
    value: Object,
    dayIndex: Number,
    formShow: Boolean,
    userRole: String,
    departmentId: Number,
    userId:Number,
    isSelfChange:{
      type: Boolean,
      default:false,
      required:false,

    }

  },

  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      userPermms: JSON.parse(localStorage.getItem('userPerms')),
      todayDate: new Date().toISOString().slice(0, 10),
      showPopover: false,
      showHover: false,
      showTimePicker: false,
      selectedPeriod: 'AM',
      start_time: this.selectedPeriod == 'AM' ? '09:00' : '14:00',
      end_time: this.selectedPeriod == 'AM' ? '13:00' : '16:00',
      startPickerConfig: this.getPickerConfig('09:00', '13:00'),
      endPickerConfig: this.getPickerConfig('09:00', '13:00'),
      newTaskId: null,
      mappedTasks: [],
      menuData: [
        { icon: 'PlusIcon', text: 'Add New Task', where: 'add' },
        { icon: 'ClockIcon', text: 'Change Time', where: 'time' },
        { icon: 'TrashIcon', text: 'Delete Task', where: 'trash' },
      ],
    };
  },

  computed: {
    displayedTaskTitle() {
      return this.day.isHovered && this.day.change_awaits ? this.day.old_task.title : this.day.task.title;
    },
    displayedColor() {
      return this.day.isHovered && this.day.change_awaits ? this.day.old_task.color : this.day.task.color;
    },

    localShowPopover: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },

  watch: {
    selectedPeriod(newPeriod) {
      this.updatePickerConfig(newPeriod);
    },

    start_time() {
      this.endPickerConfig.minTime = this.start_time;
      if (this.start_time > this.end_time) {
        this.end_time = this.start_time;
      }
    },
  },

 

  methods: {
    showOldTask(day) {
      if (day.change_awaits) {
        this.$set(day, 'isHovered', true);
      }
    },
    hideOldTask(day) {
      this.$set(day, 'isHovered', false);
    },

    buttonStyle(day) {
      if (!day.task || !day.task.color) {
        return {};
      }

      const color = this.displayedColor || day.task.color;

      return {
        backgroundColor: color.bgColor,
        color: color.textColor,
        position: 'relative',
      };
    },

    getPickerConfig(minTime, maxTime) {
      return {
        static: true,
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        time_24hr: true,


      };
    },

    updatePickerConfig(period) {
      // const minTime = period === 'AM' ? '00:00' : '12:00';
      // const maxTime = period === 'AM' ? '12:00' : '23:59';
      // this.startPickerConfig = this.getPickerConfig(minTime, maxTime);
      // this.endPickerConfig = this.getPickerConfig(minTime, maxTime);

      this.start_time = period === 'AM' ? '09:00' : '14:00';
      this.end_time = period === 'AM' ? '13:00' : '16:00';

    },

    optionClicked(text, icon, where, targetId) {
      
      let currentTask = this.tasks.filter((task) => ( task.id == targetId));
      console.log(this.tasks)
      if (where === 'time') {
        if (this.$Can('change_time_for_all') || this.userData.departmentIds.includes(this.departmentId)) {
          if (this.$Can('change_time_to_department')) {
            this.start_time = this.value.start_time;
            this.end_time = this.value.end_time;
            this.$nextTick(() => {
              if (this.$refs['time-modal-' + targetId]) {
                this.$refs['time-modal-' + targetId].show();
              } else {
                console.error(`Time modal reference not found for targetId: ${targetId}`);
              }
            });
          } else {
            this.showWarningToast('You do not have permission for this action.');
          }
        } else {
          this.showWarningToast('You do not have permission for this action.');
        }
      } else if (where === 'add') {

        if(this.selectedPeriod=='AM'){
          this.start_time = '09:00'
          this.end_time = '13:00'
        }
        else{
               this.start_time = '14:00'
          this.end_time = '16:00'
        }
        this.mappedTasks = this.tasks.map((task) => ({ label: task.title, id: task.id }));
        // Tüm kullanıcılara görev ekleme yetkisini kontrol et
        if (this.$Can('add_task_to_all') || this.userData.department_id == this.departmentId) {
          // Departman kullanıcılarına görev ekleme yetkisini ve departman eşleşmesini kontrol et
          if (this.$Can('add_task_to_department')) {
            this.$nextTick(() => {
              const modalRef = this.$refs['add-modal-' + targetId];

              if (modalRef) {
                modalRef.show();
              } else {
                console.error(`Add modal reference not found for targetId: ${targetId}`);
              }
            });
          } else {
            // Departman kullanıcılarına görev ekleme yetkisi yok
            this.showWarningToast('You do not have permission for this action.');
          }
        } else {
          // Tüm kullanıcılara görev ekleme yetkisi yok
          this.showWarningToast('You do not have permission for this action.');
        }
      } else if (where === 'trash') {
        if (this.$Can('add_task_to_all') ||this.userData.departmentIds.includes(this.departmentId)) {
          if (this.$Can('add_task_to_department')) {
            this.deleteTask(targetId);
          } else {
            this.showWarningToast('You do not have permission for this action.');
          }
        } else {
          this.showWarningToast('You do not have permission for this action.');
        }
      }
    },

    deleteTask(targetId) {
      // Confirm deletion
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this task?', {
          title: 'Confirm Deletion',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            store
              .dispatch('dashboard/deleteTask', { taskId: targetId })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ Task deleted successfully',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });
                this.$emit('changeTaskCompleted');
              })
              .catch((error) => {
                console.error(error);
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                  },
                });
              });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    saveTimeChanges(targetId) {
      this.updateScheduleTime(targetId);
    },

    saveAddChanges(targetId) {
      if (!this.newTaskId) {
        this.showWarningToast('Please select a task before saving.');
        return;
      }
      this.addNewSchedule(targetId);
    },

    updateScheduleTime(targetId) {
      store
        .dispatch('dashboard/updateScheduleTime', { task: this.value, start_time: this.start_time, end_time: this.end_time, period: this.selectedPeriod })
        .then(() => {
          this.showSuccessToast('✔️ Task Update Successful');
          this.hideModal(targetId);
          this.$emit('changeTaskCompleted');
        })
        .catch(() => {
          this.showErrorToast();
          this.$emit('formShow', false);
        });
    },

    addNewSchedule(targetId) {
      store
        .dispatch('dashboard/addNewSchedule', {
          task: this.value,
          new_task_id: this.newTaskId,
          start_time: this.start_time,
          end_time: this.end_time,
          period: this.selectedPeriod,
        })
        .then((response) => {
        if(response.data.message=='Another Site'){
          console.log(res.data.message)
        }
        else{
          this.showSuccessToast('✔️ Task Update Successful');
          this.hideModal(targetId);
          this.$emit('changeTaskCompleted');
        }
        
        })
        .catch(() => {
          this.showErrorToast();
          this.$emit('formShow', false);
        });
    },

    showSuccessToast(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: message,
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      });
    },

    showWarningToast(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Warning',
          text: message,
          icon: 'AlertTriangleIcon',
          variant: 'warning',
        },
      });
    },

    showErrorToast() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'An error occurred',
          text: 'Please try again later or contact support.',
          icon: 'AlertCircleIcon',
          variant: 'danger',
        },
      });
    },

    hideModal(targetId) {
      this.$refs['time-modal-' + targetId]?.hide();
      this.$refs['add-modal-' + targetId]?.hide();
    },

    changeTask(newTask) {
      console.log(this.userData.departmentIds.includes(this.departmentId))
      if (this.$Can('change_task_for_all') || this.userData.departmentIds.includes(this.departmentId) || this.isSelfChange) {
        if (this.$Can('change_task_for_department')  || this.userId == this.userData.id || this.isSelfChange) {
          this.$emit('formShow', true);
          store
            .dispatch('dashboard/updateSchedule', { oldTask: this.value, newTask })
            .then(() => {
              this.showSuccessToast('✔️ Task Update Successful');
              this.$root.$emit('bv::hide::popover');
              this.$emit('changeTaskCompleted');
            })
            .catch(() => {
              this.showErrorToast();
              this.$emit('formShow', false);
            });
        
        }
        
        else {
          this.showWarningToast('You do not have permission for this action.');
        }
      } else {
        this.showWarningToast('You do not have permission for this action.');
      }
    },
  },
};
</script>

<style scoped>
.time-picker-container {
  display: flex;
  align-items: center;
}
.time-picker-container > * {
  margin-right: 10px;
}
.popoverBtn {
  height: 36px;
  font-size: 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
  width: 100%;
  border: none;
  cursor: pointer;
  width: calc(100% - 40px);
}
.popoverBtn:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3) !important;
  transition: box-shadow 0.3s ease-in-out !important;
}
.time-info-container {
  display: flex;
  justify-content: space-between;
  border-top: solid 1px #666;
}
.time-info {
  display: inline-block;
}

.strikethrough {
  text-decoration: line-through;
}

@media screen and (min-width: 768px) {
  .popoverBtn {
    min-width: 150px;
    max-width: 150px;
  }
}
@media screen and (min-width: 992px) {
  .popoverBtn {
    min-width: 125px;
    max-width: 125px;
  }
}
@media screen and (min-width: 1900px) {
  .popoverBtn {
    min-width: 220px;
    max-width: 220px;
  }
}
</style>
<style lang="scss">
@import '~vue-context/dist/css/vue-context.css';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
